<template>
    <div class="box"
        ref="box"
        :class="{show:showing}">

        <div class="header">

        </div>
        <div class="body">
            <slot></slot>
        </div>
        <div class="footer"
            v-if="applyLabel">
            <button type="button"
                @click="onCloseBtnClick"
                class="btn btn-primary">{{applyLabel}}</button>
        </div>


        <div class="arrow"
            data-popper-arrow></div>
    </div>

</template>

<script>
    import V from 'voUtils/V.js'

    import {
    	createPopper
    }
    from '@popperjs/core';

    export default {
    	voVueComponent: 'voffice-popup-box',
    	props: {
    		triggerId: String,
    		applyLabel: String
    	},
    	data: function() {
    		return {
    			showing: false
    		}
    	},

    	mounted: function() {
    		this.triggerEl = document.getElementById(this.triggerId);

    		if (this.triggerEl) {

    			this.triggerEl.addEventListener('click', this.toggleShow);
    			//this.triggerEl.addEventListener('touchend', this.toggleShow);

    		}

    		function stopPropagation(event) {
    			event.stopPropagation();
    		}
    		this.$el.addEventListener("click", stopPropagation);
    		//this.$el.addEventListener("touchend", stopPropagation);

    		this.onOutsideClick = event => {
    			if (!event.target || !this.triggerEl || !(event.target.isSameNode(this.triggerEl) || this.triggerEl.contains(event.target))) {
    				this.hide();
    			}
    		};

    		let closeOnClicks = this.$el.querySelectorAll('[data-close-on-click]');
    		for (let cel of closeOnClicks) {
    			cel.addEventListener("click", this.hide);
    			//cel.addEventListener("touchend", this.hide);
    		}


    	},
    	methods: {
    		onCloseBtnClick: function() {
    			//	console.log("close!");
    			this.hide();
    		},
    		create: function() {
    			this.popperInstance = createPopper(this.triggerEl, this.$refs.box, {
    				modifiers: [{
    					name: 'offset',
    					options: {
    						offset: [0, 8],
    					}
    				}]
    			});
    			window.addEventListener("click", this.onOutsideClick, false);
    			//window.addEventListener("touchend", this.onOutsideClick, false);
    		},
    		destroy: function() {
    			if (this.popperInstance) {
    				this.popperInstance.destroy();
    				this.popperInstance = null;

    				window.removeEventListener("click", this.onOutsideClick, false);
    				//window.removeEventListener("touchend", this.onOutsideClick, false);
    			}
    			this.$emit('closed');
    		},
    		show: function() {
    			this.showing = true;
    		},
    		hide: function() {
    			this.showing = false;
    		},
    		toggleShow: function(event) {
    			this.showing = !this.showing;
    		}
    	},
    	watch: {
    		showing: function() {
    			if (this.showing) {
    				this.create();
    			} else {
    				this.destroy();
    			}

    		}
    	}
    }
</script>


<style scoped>
    .box {
    	display: none;
    	z-index: 999;
    	background: #ffffff;
    	border: solid 1px #cccccc;

    	border-radius: 8px;
    	box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
    }

    .body {
    	padding: 22px 15px;
    }


    .footer {
    	border-top: 1px solid rgb(235, 235, 235);
    	padding: 12px 15px;
    	text-align: right;
    }

    .box.show {
    	display: block;
    }

    .arrow,
    .arrow::before {
    	position: absolute;
    	width: 8px;
    	height: 8px;
    	z-index: -1;
    }

    .arrow::before {
    	content: '';
    	transform: rotate(45deg);
    	background: white;
    }

    .box[data-popper-placement^='top']>.arrow {
    	bottom: -4px;
    }

    .box[data-popper-placement^='bottom']>.arrow {
    	top: -4px;
    }

    .box[data-popper-placement^='left']>.arrow {
    	right: -4px;
    }

    .box[data-popper-placement^='right']>.arrow {
    	left: -4px;
    }

    @define-mixin fullscreen {
    	.arrow {
    		display: none;
    	}

    	position: fixed !important;
    	left: 0px !important;
    	right: 0px !important;
    	bottom: 0px !important;
    	top: 0px !important;
    	margin: 0px !important;
    	border-radius: 0px;
    	border: none;
    	width: 100%;
    	transform: none !important;

    	.body {
    		width: 100%;
    	}

    }

    @media (max-width:767px) {
    	.box.full-xs {
    		@mixin fullscreen;
    	}
    }

    @media (max-width:480px) {
    	.box.full-xxs {
    		@mixin fullscreen;
    	}
    }
</style>